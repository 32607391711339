import React, { useState } from 'react';
import { Form, Button, ProgressBar } from 'react-bootstrap';
import { FaChild, FaSchool, FaEnvelope, FaPhone } from 'react-icons/fa'; // Import icons
import emailjs from 'emailjs-com'; // Import EmailJS
import './Survey.css';

const Survey = ({ onClose }) => {
  const [step, setStep] = useState(1);
  const [answers, setAnswers] = useState({
    childName: '',
    schoolType: '',
    className: '',
    level: 'podstawowy',
    email: '',
    phone: '',
  });
  const [errors, setErrors] = useState({});
  const [showConfirmation, setShowConfirmation] = useState(false);

  const handleChange = (e) => {
    setAnswers({ ...answers, [e.target.name]: e.target.value });
  };

  const handleSchoolTypeChange = (type) => {
    setAnswers({ ...answers, schoolType: type, className: '' });
    setStep(3);
  };

  const validateField = (name, value) => {
    switch (name) {
      case 'childName':
        return value.trim() === '' || !/^[a-zA-Z\s]+$/.test(value)
          ? 'Imię dziecka musi zawierać tylko litery i nie może być puste.'
          : '';
      case 'email':
        return !/^[a-zA-Z0-9._%+-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,}$/.test(value)
          ? 'Podaj poprawny adres email.'
          : '';
      case 'phone':
        return !/^\d{9,15}$/.test(value)
          ? 'Numer telefonu musi zawierać od 9 do 15 cyfr.'
          : '';
      case 'className':
        return value === ''
          ? 'Wybierz klasę.'
          : '';
      default:
        return '';
    }
  };

  const validateForm = () => {
    const newErrors = {};
    Object.keys(answers).forEach((key) => {
      const error = validateField(key, answers[key]);
      if (error) newErrors[key] = error;
    });
    setErrors(newErrors);
    return Object.keys(newErrors).length === 0;
  };

  const handleNextStep = (e) => {
    e.preventDefault();
    const currentField = Object.keys(answers)[step - 1];
    const error = validateField(currentField, answers[currentField]);
    if (error) {
      setErrors({ ...errors, [currentField]: error });
      return;
    }
    setErrors({});
    setStep(step + 1);
  };

  const handlePreviousStep = () => {
    if (step > 1) setStep(step - 1);
  };

  const handleKeyPress = (e, fieldName) => {
    if (e.key === 'Enter') {
      const error = validateField(fieldName, answers[fieldName]);
      if (error) {
        setErrors({ ...errors, [fieldName]: error });
        e.preventDefault();
      } else {
        handleNextStep(e);
      }
    }
  };

  const handleSubmit = (e) => {
    e.preventDefault();
    if (!validateForm()) return;

    // Send email using EmailJS
    emailjs
      .send(
        'service_matfiz', // Replace with your EmailJS Service ID
        'template_0sjx71s', // Replace with your EmailJS Template ID
        {
          child_name: answers.childName,
          school_type: answers.schoolType,
          class_name: answers.className,
          level: answers.level,
          email: answers.email,
          phone: answers.phone,
        },
        'PjiziqdDLJKLximRG' // Replace with your EmailJS User ID
      )
      .then(
        (result) => {
          console.log('Email sent successfully:', result.text);
          setShowConfirmation(true); // Display confirmation message
          setStep(0); // Reset step to prevent rendering form steps
        },
        (error) => {
          console.error('Error sending email:', error.text);
          alert('Wystąpił błąd podczas wysyłania formularza. Spróbuj ponownie później.');
        }
      );
  };

  const getClasses = () => {
    switch (answers.schoolType) {
      case 'podstawowka':
        return Array.from({ length: 8 }, (_, i) => i + 1);
      case 'liceum':
        return Array.from({ length: 4 }, (_, i) => i + 1);
      case 'technikum':
        return Array.from({ length: 5 }, (_, i) => i + 1);
      default:
        return [];
    }
  };

  const getProgress = () => {
    const totalSteps = 5;
    return ((step - 1) / totalSteps) * 100;
  };

  const handleReturnToMain = () => {
    onClose();
  };

  const renderStep = () => {
    switch (step) {
      case 1:
        return (
          <>
            <p className="step-description">Podaj imię dziecka, abyśmy mogli lepiej dostosować ofertę.</p>
            <Form.Group className="mb-3">
              <Form.Label>
                <FaChild style={{ color: '#3498db', marginRight: '8px' }} />
                Imię dziecka:
              </Form.Label>
              <Form.Control
                type="text"
                name="childName"
                value={answers.childName}
                onChange={(e) => {
                  handleChange(e);
                  setErrors({ ...errors, childName: validateField('childName', e.target.value) });
                }}
                isInvalid={!!errors.childName}
                onKeyPress={(e) => handleKeyPress(e, 'childName')}
                style={{ borderColor: errors.childName ? '#e74c3c' : '#ccc' }}
              />
              <Form.Control.Feedback type="invalid">{errors.childName}</Form.Control.Feedback>
              <div className="button-group">
                <Button
                  className="mt-2"
                  onClick={step === 1 ? handleReturnToMain : handlePreviousStep} // Return to main page on step 1
                  style={{
                    backgroundColor: 'transparent', // Remove red background
                    borderColor: '#e74c3c',
                    color: '#e74c3c', // Keep red text for consistency
                  }}
                >
                  Cofnij
                </Button>
                {step < 5 ? (
                  <Button
                    className="mt-2"
                    onClick={handleNextStep}
                    style={{
                      backgroundColor: '#27ae60',
                      borderColor: '#27ae60',
                      color: '#f7f9fc', // Updated from #fff
                    }}
                  >
                    Dalej
                  </Button>
                ) : (
                  <Button
                    className="mt-2"
                    variant="primary"
                    type="submit"
                    onClick={handleSubmit}
                    style={{
                      backgroundColor: '#27ae60',
                      borderColor: '#27ae60',
                      color: '#f7f9fc', // Updated from #fff
                    }}
                  >
                    Wyślij
                  </Button>
                )}
              </div>
            </Form.Group>
          </>
        );
      case 2:
        return (
          <>
            <p className="step-description">Wybierz typ szkoły, do której uczęszcza dziecko.</p>
            <Form.Group className="mb-3">
              <Form.Label>
                <FaSchool style={{ color: '#f39c12', marginRight: '8px' }} />
                Typ szkoły:
              </Form.Label>
              <div className="d-flex justify-content-between">
                <Button
                  variant={answers.schoolType === 'podstawowka' ? 'primary' : 'outline-primary'}
                  onClick={() => handleSchoolTypeChange('podstawowka')}
                  style={{
                    backgroundColor: answers.schoolType === 'podstawowka' ? '#3498db' : 'transparent',
                    borderColor: '#3498db',
                    color: answers.schoolType === 'podstawowka' ? '#f7f9fc' : '#3498db', // Updated from #fff
                  }}
                >
                  Podstawówka
                </Button>
                <Button
                  variant={answers.schoolType === 'liceum' ? 'primary' : 'outline-primary'}
                  onClick={() => handleSchoolTypeChange('liceum')}
                  style={{
                    backgroundColor: answers.schoolType === 'liceum' ? '#3498db' : 'transparent',
                    borderColor: '#3498db',
                    color: answers.schoolType === 'liceum' ? '#f7f9fc' : '#3498db', // Updated from #fff
                  }}
                >
                  Liceum
                </Button>
                <Button
                  variant={answers.schoolType === 'technikum' ? 'primary' : 'outline-primary'}
                  onClick={() => handleSchoolTypeChange('technikum')}
                  style={{
                    backgroundColor: answers.schoolType === 'technikum' ? '#3498db' : 'transparent',
                    borderColor: '#3498db',
                    color: answers.schoolType === 'technikum' ? '#f7f9fc' : '#3498db', // Updated from #fff
                  }}
                >
                  Technikum
                </Button>
              </div>
              <div className="button-group">
                <Button
                  className="mt-2"
                  onClick={step === 1 ? handleReturnToMain : handlePreviousStep} // Return to main page on step 1
                  style={{
                    backgroundColor: 'transparent', // Remove red background
                    borderColor: '#e74c3c',
                    color: '#e74c3c', // Keep red text for consistency
                  }}
                >
                  Cofnij
                </Button>
              </div>
            </Form.Group>
          </>
        );
      case 3:
        return (
          <>
            <p className="step-description">Wybierz klasę dziecka.</p>
            <Form.Group className="mb-3">
              <Form.Label>
                <FaSchool style={{ color: '#9b59b6', marginRight: '8px' }} />
                Klasa:
              </Form.Label>
              <Form.Control
                as="select"
                name="className"
                value={answers.className}
                onChange={(e) => {
                  handleChange(e);
                  setErrors({ ...errors, className: validateField('className', e.target.value) });
                }}
                isInvalid={!!errors.className}
                style={{ borderColor: errors.className ? '#e74c3c' : '#ccc' }}
              >
                <option value="">Wybierz klasę</option>
                {getClasses().map((cls) => (
                  <option key={cls} value={cls}>
                    {cls}
                  </option>
                ))}
              </Form.Control>
              <Form.Control.Feedback type="invalid">{errors.className}</Form.Control.Feedback>
            </Form.Group>
            <div className="button-group">
              <Button
                className="mt-2"
                onClick={step === 1 ? handleReturnToMain : handlePreviousStep}
                style={{
                  backgroundColor: 'transparent',
                  borderColor: '#e74c3c',
                  color: '#e74c3c',
                }}
              >
                Cofnij
              </Button>
              <Button
                className="mt-2"
                onClick={handleNextStep}
                style={{
                  backgroundColor: '#27ae60',
                  borderColor: '#27ae60',
                  color: '#f7f9fc', // Updated from #fff
                }}
              >
                Dalej
              </Button>
            </div>
          </>
        );
      case 4:
        if (answers.schoolType === 'liceum' || answers.schoolType === 'technikum') {
          return (
            <>
              <p className="step-description">Wybierz poziom nauczania.</p>
              <Form.Group className="mb-3">
                <Form.Label>
                  <FaSchool style={{ color: '#16a085', marginRight: '8px' }} />
                  Poziom:
                </Form.Label>
                <Form.Control
                  as="select"
                  name="level"
                  value={answers.level}
                  onChange={handleChange}
                  style={{ borderColor: '#ccc' }}
                >
                  <option value="podstawowy">Podstawowy</option>
                  <option value="rozszerzony">Rozszerzony</option>
                </Form.Control>
              </Form.Group>
              <div className="button-group">
                <Button
                  className="mt-2"
                  onClick={handlePreviousStep}
                  style={{
                    backgroundColor: 'transparent',
                    borderColor: '#e74c3c',
                    color: '#e74c3c',
                  }}
                >
                  Cofnij
                </Button>
                <Button
                  className="mt-2"
                  onClick={handleNextStep}
                  style={{
                    backgroundColor: '#27ae60',
                    borderColor: '#27ae60',
                    color: '#f7f9fc', // Updated from #fff
                  }}
                >
                  Dalej
                </Button>
              </div>
            </>
          );
        } else {
          setStep(5); // Skip this step for "podstawowka"
          return null;
        }
      case 5:
        return (
          <>
            <p className="step-description">Podaj dane kontaktowe, abyśmy mogli się z Tobą skontaktować.</p>
            <Form.Group className="mb-3">
              <Form.Label>
                <FaEnvelope style={{ color: '#e67e22', marginRight: '8px' }} />
                Email:
              </Form.Label>
              <Form.Control
                type="email"
                name="email"
                value={answers.email}
                onChange={(e) => {
                  handleChange(e);
                  setErrors({ ...errors, email: validateField('email', e.target.value) });
                }}
                isInvalid={!!errors.email}
                onKeyPress={(e) => handleKeyPress(e, 'email')}
                style={{ borderColor: errors.email ? '#e74c3c' : '#ccc' }}
              />
              <Form.Control.Feedback type="invalid">{errors.email}</Form.Control.Feedback>
            </Form.Group>

            <Form.Group className="mb-3">
              <Form.Label>
                <FaPhone style={{ color: '#2980b9', marginRight: '8px' }} />
                Numer telefonu:
              </Form.Label>
              <Form.Control
                type="tel"
                name="phone"
                value={answers.phone}
                onChange={(e) => {
                  handleChange(e);
                  setErrors({ ...errors, phone: validateField('phone', e.target.value) });
                }}
                isInvalid={!!errors.phone}
                onKeyPress={(e) => handleKeyPress(e, 'phone')}
                style={{ borderColor: errors.phone ? '#e74c3c' : '#ccc' }}
              />
              <Form.Control.Feedback type="invalid">{errors.phone}</Form.Control.Feedback>
            </Form.Group>

            <div className="button-group">
              <Button
                className="mt-2"
                onClick={step === 1 ? handleReturnToMain : handlePreviousStep} // Return to main page on step 1
                style={{
                  backgroundColor: 'transparent', // Remove red background
                  borderColor: '#e74c3c',
                  color: '#e74c3c', // Keep red text for consistency
                }}
              >
                Cofnij
              </Button>
              {step < 5 ? (
                <Button
                  className="mt-2"
                  onClick={handleNextStep}
                  style={{
                    backgroundColor: '#27ae60',
                    borderColor: '#27ae60',
                    color: '#f7f9fc', // Updated from #fff
                  }}
                >
                  Dalej
                </Button>
              ) : (
                <Button
                  className="mt-2"
                  variant="primary"
                  type="submit"
                  onClick={handleSubmit}
                  style={{
                    backgroundColor: '#27ae60',
                    borderColor: '#27ae60',
                    color: '#f7f9fc', // Updated from #fff
                  }}
                >
                  Wyślij
                </Button>
              )}
            </div>
          </>
        );
      default:
        return null;
    }
  };

  return (
    <div className="survey-container">
      <div className="survey-card">
        <div className="survey-header">
          <h2>Dopasujemy się do Twoich potrzeb</h2> {/* Updated header text */}
          {!showConfirmation && step > 0 && ( // Hide step indicator after submission
            <>
              <p className="step-indicator">Krok {step} z 5</p>
              <ProgressBar now={getProgress()} className="progress-bar-custom" />
            </>
          )}
        </div>
        <div className="survey-content">
          {showConfirmation ? (
            <div className="confirmation-message">
              <h2>Dziękujemy!</h2>
              <p>Skontaktujemy się z Tobą w ciągu 24h.</p> {/* Display contact message */}
              <Button onClick={handleReturnToMain} className="return-button">
                Powrót na stronę główną
              </Button>
            </div>
          ) : (
            <Form onSubmit={handleSubmit}>{renderStep()}</Form>
          )}
        </div>
      </div>
    </div>
  );
};

export default Survey;
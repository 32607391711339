import React, { useState } from 'react';
import './MainContent.css';
import Survey from './Survey';
import Container from 'react-bootstrap/Container';
import Nav from 'react-bootstrap/Nav';
import Navbar from 'react-bootstrap/Navbar';
import NavDropdown from 'react-bootstrap/NavDropdown';
import Carousel from 'react-bootstrap/Carousel';

function Counter({ targetNumber, duration, suffix }) {
    const [currentNumber, setCurrentNumber] = useState(0);

    React.useEffect(() => {
        let start = 0;
        const increment = targetNumber / (duration * 60); // Increment per frame (60fps)
        const interval = setInterval(() => {
            start += increment;
            if (start >= targetNumber) {
                setCurrentNumber(targetNumber);
                clearInterval(interval);
            } else {
                setCurrentNumber(Math.ceil(start));
            }
        }, 1000 / 60); // 60fps
        return () => clearInterval(interval);
    }, [targetNumber, duration]);

    return <p>{currentNumber.toLocaleString()}{suffix}</p>;
}

const Footer = () => (
    <footer className="footer">
        <p>&copy; {new Date().getFullYear()} EasyMath. Wszelkie prawa zastrzeżone.</p>
        <p>
            <a href="/privacy-policy">Polityka prywatności</a> | <a href="/terms-of-service">Regulamin</a>
        </p>
    </footer>
);

function MainContent() {
    const [showSurvey, setShowSurvey] = useState(false);
    const [showLinks, setShowLinks] = useState(false);

    const handleOpenSurvey = () => {
        setShowSurvey(true);
    };

    const handleCloseSurvey = () => {
        setShowSurvey(false);
    };

    const toggleLinks = () => {
        setShowLinks(!showLinks);
    };

    return (
        <div className="main-content">
            <Navbar expand="lg" className="bg-body-tertiary">
                <Container>
                    <Navbar.Brand href="#home">EasyMath</Navbar.Brand>
                    <Navbar.Toggle aria-controls="basic-navbar-nav" />
                    <Navbar.Collapse id="basic-navbar-nav">
                        <Nav className="me-auto">
                            <Nav.Link href="#onas">O nas</Nav.Link>
                            <Nav.Link href="#cennik">Cennik</Nav.Link>
                            <Nav.Link href="#kontakt">Kontakt</Nav.Link>
                            <NavDropdown title="Więcej" id="basic-nav-dropdown">
                                <NavDropdown.Item href="#action/1">Polityka prywatności</NavDropdown.Item>
                                <NavDropdown.Item href="#action/2">Regulamin</NavDropdown.Item>
                            </NavDropdown>
                        </Nav>
                    </Navbar.Collapse>
                </Container>
            </Navbar>
            {showSurvey ? (
                <Survey onClose={handleCloseSurvey} />
            ) : (
                <>
                    <section className="first-section">
                        <div className="main-girl-image">
                            <img alt="Girl" src="/easymath_image.png" />
                        </div>

                        <div className="first-subsection">
                            <h1>
                                Twoje dziecko zasługuje na nasze&nbsp;wsparcie w&nbsp;nauce 
                                <span> matematyki!</span>
                            </h1>
                            <div
                                onClick={handleOpenSurvey}
                                id="main-button1"
                                className="first-subsection-button main-menu-popup click_free-consult_main"
                                style={{
                                    backgroundColor: '#2ecc71',
                                    color: '#fff',
                                    padding: '10px 20px',
                                    borderRadius: '5px',
                                    cursor: 'pointer',
                                    textAlign: 'center',
                                    fontWeight: 'bold',
                                }}
                            >
                                Umów się
                            </div>
                            <div className="reviews-section">
                                <div className="tutors-amount" style={{ color: '#3498db' }}>
                                    <Counter targetNumber={1250} duration={1} suffix="+" />
                                    <span>godzin zajęć poświęconych uczniom</span>
                                </div>
                                <div className="reviews-amount" style={{ color: '#2ecc71' }}>
                                    <Counter targetNumber={100} duration={1.5} suffix="%" />
                                    <span>zdawalności egzaminów CKE</span>
                                </div>
                                <div className="rate-amount" style={{ color: '#3498db' }}>
                                    <div className="stars-amount">
                                        <Counter targetNumber={98} duration={2} suffix="%" />
                                        <span>zadowolonych uczniów i rodziców</span>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </section>
                    <section className="carousel-section">
                        <Carousel>
                            <Carousel.Item>
                                <div
                                    className="d-block w-100"
                                    style={{
                                        backgroundImage: 'url("/tlo_gradient.png")',
                                        backgroundSize: 'cover',
                                        backgroundPosition: 'center',
                                        height: '400px',
                                    }}
                                ></div>
                                <Carousel.Caption style={{ textAlign: 'center', color: '#fff', textShadow: '0 2px 5px rgba(0, 0, 0, 0.7)' }}>
                                    <h3 style={{ fontSize: '2rem', fontWeight: 'bold', marginBottom: '10px' }}>Krok 1: Wypełnij ankietę</h3>
                                    <p style={{ fontSize: '1.2rem', marginBottom: '20px' }}>
                                        Podaj dane kontaktowe i potrzeby dziecka. Skontaktujemy się z Tobą.
                                    </p>
                                    <button
                                        className="carousel-button"
                                        style={{
                                            backgroundColor: '#f7f9fc',
                                            color: '#000',
                                            padding: '10px 20px',
                                            borderRadius: '5px',
                                            border: 'none',
                                            cursor: 'pointer',
                                            fontWeight: 'bold',
                                            boxShadow: '0 4px 8px rgba(0, 0, 0, 0.1)',
                                        }}
                                        onClick={() => alert('Umów się clicked!')}
                                    >
                                        Umów się
                                    </button>
                                </Carousel.Caption>
                            </Carousel.Item>
                            <Carousel.Item>
                                <div
                                    className="d-block w-100"
                                    style={{
                                        backgroundImage: 'url("/tlo_gradient.png")',
                                        backgroundSize: 'cover',
                                        backgroundPosition: 'center',
                                        height: '400px',
                                    }}
                                ></div>
                                <Carousel.Caption style={{ textAlign: 'center', color: '#fff', textShadow: '0 2px 5px rgba(0, 0, 0, 0.7)' }}>
                                    <h3 style={{ fontSize: '2rem', fontWeight: 'bold', marginBottom: '10px' }}>Krok 2: Lekcja diagnostyczna</h3>
                                    <p style={{ fontSize: '1.2rem', marginBottom: '20px' }}>
                                        Twoje dziecko uczestniczy w lekcji, a my dopasowujemy plan nauki.
                                    </p>
                                    <button
                                        className="carousel-button"
                                        style={{
                                            backgroundColor: '#f7f9fc',
                                            color: '#000',
                                            padding: '10px 20px',
                                            borderRadius: '5px',
                                            border: 'none',
                                            cursor: 'pointer',
                                            fontWeight: 'bold',
                                            boxShadow: '0 4px 8px rgba(0, 0, 0, 0.1)',
                                        }}
                                        onClick={() => alert('Umów się clicked!')}
                                    >
                                        Umów się
                                    </button>
                                </Carousel.Caption>
                            </Carousel.Item>
                            <Carousel.Item>
                                <div
                                    className="d-block w-100"
                                    style={{
                                        backgroundImage: 'url("/tlo_gradient.png")',
                                        backgroundSize: 'cover',
                                        backgroundPosition: 'center',
                                        height: '400px',
                                    }}
                                ></div>
                                <Carousel.Caption style={{ textAlign: 'center', color: '#fff', textShadow: '0 2px 5px rgba(0, 0, 0, 0.7)' }}>
                                    <h3 style={{ fontSize: '2rem', fontWeight: 'bold', marginBottom: '10px' }}>Krok 3: Rozpocznij naukę</h3>
                                    <p style={{ fontSize: '1.2rem', marginBottom: '20px' }}>
                                        Wybierz pakiet zajęć i rozpocznij naukę z nami!
                                    </p>
                                    <button
                                        className="carousel-button"
                                        style={{
                                            backgroundColor: '#f7f9fc',
                                            color: '#000',
                                            padding: '10px 20px',
                                            borderRadius: '5px',
                                            border: 'none',
                                            cursor: 'pointer',
                                            fontWeight: 'bold',
                                            boxShadow: '0 4px 8px rgba(0, 0, 0, 0.1)',
                                        }}
                                        onClick={() => alert('Umów się clicked!')}
                                    >
                                        Umów się
                                    </button>
                                </Carousel.Caption>
                            </Carousel.Item>
                        </Carousel>
                    </section>
                </>
            )}
            <Footer /> {/* Ensure footer is outside the first-section */}
        </div>
    );
}

export default MainContent;
import React from 'react';
import './App.css'; // Keep the existing App.css for any general styles
import MainContent from './components/MainContent'; // Create a new component for the main content

function App() {
  return (
    <div className="App">
      <MainContent />
    </div>
  );
}

export default App;